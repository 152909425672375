
export default {
  name: 'table-pagination',
  props: {
    currentPage: Number,
    pageSize: Number,
    totalItems: Number,
    onPageChange: Function,
    onPageSizeChange: Function,
    search: Boolean,
  },
  computed: {
    start(vm: { pageSize: number; currentPage: number }) {
      return vm.pageSize * (vm.currentPage - 1) + 1;
    },
    end(vm: { pageSize: number; currentPage: number; totalItems: number }) {
      return Math.min(vm.pageSize * vm.currentPage, vm.totalItems);
    },
    pageCount(vm: { pageSize: number; totalItems: number }) {
      return Math.ceil(vm.totalItems / vm.pageSize);
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props: any) {
    if (props.onPageChange) props.onPageChange(1);

    return {
      pageSizeOptions: [1, 5, 10, 25, 50],
    };
  },
};
