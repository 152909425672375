import { getAccessToken } from '@/util/Auth';
import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client/core';

const protocol = process.env.VUE_APP_BACKEND_PROTOCOL || 'http';
const host = process.env.VUE_APP_BACKEND_HOST || 'localhost:8080';
// const client = process.env.VUE_APP_DEFAULT_CLIENT || 'marksmen';

const httpLink = new HttpLink({ uri: `${protocol}://${host}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: getAccessToken() ? `JWT ${getAccessToken()}` : '',
    },
  });
  return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
});
