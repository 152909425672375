import Cookies from 'js-cookie';

const expires = 24 * 60 * 60 * 1000;
const refreshExpires = 21 * 24 * 60 * 60 * 1000;

export const handle_login_result = (
  accessToken: string,
  refreshToken: string,
  rememberMe = false
) => {
  const inOneDay = new Date(new Date().getTime() + expires);
  const inThreeWeeks = new Date(new Date().getTime() + refreshExpires);

  Cookies.set('token', accessToken, {
    expires: rememberMe ? inOneDay : null,
    domain: process.env.VUE_APP_DOMAIN,
  });
  Cookies.set('refreshToken', refreshToken, {
    expires: rememberMe ? inThreeWeeks : null,
    domain: process.env.VUE_APP_DOMAIN,
  });
};

export const logout = () => {
  Cookies.remove('companyID', {
    domain: process.env.VUE_APP_DOMAIN,
  });
  Cookies.remove('companyLabel', {
    domain: process.env.VUE_APP_DOMAIN,
  });
  Cookies.remove('refreshToken', {
    domain: process.env.VUE_APP_DOMAIN,
  });
  Cookies.remove('token', {
    domain: process.env.VUE_APP_DOMAIN,
  });
};

export const setCompany = (company: { key: string; name: string }) => {
  const inOneDay = new Date(new Date().getTime() + expires);

  Cookies.set('companyID', company.key, {
    expires: inOneDay,
    domain: process.env.VUE_APP_DOMAIN,
  });
  Cookies.set('companyLabel', company.name, {
    expires: inOneDay,
    domain: process.env.VUE_APP_DOMAIN,
  });
};

export const getCompany = (): { companyID: string; companyLabel: string } => ({
  companyID: Cookies.get('companyID'),
  companyLabel: Cookies.get('companyLabel'),
});

export const getAccessToken = (): string => Cookies.get('token');
export const getRefreshToken = (): string => Cookies.get('refreshToken');

export const isAuthenticated = !!getAccessToken();
