
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import TablePagination from '@/components/ui/TablePagination.vue';
import SingleLine from '@/components/ui/input/SingleLine.vue';
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue';

export default defineComponent({
  name: 'data-table',
  components: {
    TablePagination,
    SingleLine,
    LoadingIndicator,
  },
  props: {
    showingDetail: Boolean,
    loading: Boolean,
    hover: Boolean,
    compact: Boolean,
    showDifferenceInPercentage: Boolean,
    title: String,

    spreadEvenly: {
      type: Boolean,
      default: true,
    },

    initialSort: Boolean,
    data: Array,
    previousData: Array,
    showProgress: Boolean,

    searchId: String,
    idColumn: String,

    emptyText: {
      type: String,
      default: 'Nothing to show',
    },

    numbers: Boolean,
    onClick: Function,
    onBack: Function,

    headers: Object,
    error: {
      type: [String, Boolean],
    },
    clickableColumn: {
      type: [String, Boolean],
    },
    actions: {
      type: [Array, Function],
    },

    suffixes: {
      type: Array,
      default: () => [] as string[],
    },
    prefixes: {
      type: Array,
      default: () => [] as string[],
    },
    decimals: {
      type: Array,
      default: () => [] as number[],
    },
    groupByCount: Number,
  },
  data() {
    return {
      itemStatus: [
        'active with errors',
        'inactive',
        'archive',
        'expired',
        'invited',
        'active',
        'draft',
        'pause',
      ],
      currentPage: 1 as number,
      pageSize: 10000 as number,
      itemLabel: 'items' as string,
      searchCriteria: '' as string,
      initialized: false,
      sort: {
        key: '' as string,
        asc: true as boolean,
      },
      hoveringOver: -1,
    };
  },
  computed: {
    filteredData(): any[] {
      return this.filterData(this.data);
    },
    formattedData(): any[] {
      return this.formatData(this.data);
    },
    formattedPreviousData(): any[] {
      return this.formatData(this.previousData);
    },
    groupBy(): string {
      return Object.keys(this.headers)[0];
    },
  },
  watch: {
    data() {
      if (this.data?.length && !this.initialized && this.initialSort) {
        this.sort.key = Object.keys(this.data[0])[0];
        this.sort.asc = false;
      }
    },
  },
  methods: {
    handleMouseOver(row: number) {
      this.hoveringOver = row;
    },
    handleExit() {
      this.hoveringOver = -1;
    },
    isNumber(value: any): boolean {
      if (typeof value == 'number') {
        return true;
      }
      if (typeof value == 'string' && value.includes('%')) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evaluate(string: string, _object: any): boolean | string {
      if (!string) {
        return null;
      }
      if (!string.includes('$')) {
        return string;
      }
      const regex = /\$.*?(?=[.? )[\]]|$)/g;
      let terms = string;

      terms = terms.replaceAll(regex, (term: string) => {
        return `_object['${term.substring(1).replaceAll(/__/g, "']['")}']`;
      });

      return eval(terms);
    },
    compareData(key: string | number, index_key: string | number) {
      const currentData = this.formattedData.find(
        (e: any) => e[this.groupBy] === index_key
      );
      const previousData = this.formattedPreviousData.find(
        (e: any) => e[this.groupBy] === index_key
      );

      if (
        (previousData ? previousData[key] : 0) === 0 &&
        currentData[key] === 0
      ) {
        return 0;
      }

      if (!this.showDifferenceInPercentage) {
        return currentData[key] - (previousData ? previousData[key] : 0);
      }

      if ((previousData ? previousData[key] : 0) <= 0) {
        return Infinity;
      }

      const percent = (currentData[key] / previousData[key]) * 100 - 100;

      return percent.toFixed(1);
    },
    stringifyPercentage(number: number) {
      if (!this.showDifferenceInPercentage)
        return `${number % 1 ? number.toFixed(2) : number}`;
      if (number == 0) return number;
      if (number === Infinity) return '∞%';
      return `${number}%`;
    },
    handleOnSort(key: string) {
      const nextSort = this.sort.key === key ? !this.sort.asc : false;
      this.sort = { key, asc: nextSort };
      this.currentPage = 1;
    },
    handleOnPageChange(page: number) {
      this.currentPage = page;
    },
    handlePageSizeChange(pageSize: number) {
      this.currentPage = 1;
      this.pageSize = pageSize;
    },
    handleSearchChange(e: string | number) {
      this.currentPage = 1;
      this.searchCriteria = String(e).toLowerCase();
    },
    filterData(data: any) {
      if (this.loading) return [];
      if (!this.searchId) return data;

      const filter = (d: any) => {
        if (typeof d[this.searchId] === 'object' && d[this.searchId].icon) {
          return d[this.searchId].label
            .toLowerCase()
            .includes(this.searchCriteria);
        }
        return d[this.searchId].toLowerCase().includes(this.searchCriteria);
      };

      return data?.filter(filter) || [];
    },
    formatData(data: any) {
      const multiplier = this.sort.asc ? -1 : 1;

      const sorter = (d1: any, d2: any) => {
        if (typeof d1[this.sort.key] === 'object' && d1[this.sort.key].icon) {
          if (d1[this.sort.key].label === d2[this.sort.key].label) return 0;
          else if (d1[this.sort.key].label === null) return 1;
          else if (d2[this.sort.key].label === null) return -1;
          else if (typeof d1[this.sort.key].label === 'string')
            return (
              d1[this.sort.key].label.localeCompare(
                d2[this.sort.key].label,
                undefined,
                {
                  numeric: true,
                  sensitivity: 'base',
                }
              ) * multiplier
            );
          else if (typeof d1[this.sort.key].label === 'number')
            return (
              (d2[this.sort.key].label - d1[this.sort.key].label) * multiplier
            );
        } else if (typeof d1[this.sort.key] === 'boolean') {
          return (
            String(d2[this.sort.key]).localeCompare(String(d1[this.sort.key])) *
            multiplier
          );
        } else {
          if (d1[this.sort.key] === d2[this.sort.key]) return 0;
          else if (d1[this.sort.key] === null) return 1;
          else if (d2[this.sort.key] === null) return -1;
          else if (typeof d1[this.sort.key] === 'string')
            return (
              d1[this.sort.key].localeCompare(d2[this.sort.key], undefined, {
                numeric: true,
                sensitivity: 'base',
              }) * multiplier
            );
          else if (typeof d1[this.sort.key] === 'number')
            return (d2[this.sort.key] - d1[this.sort.key]) * multiplier;
        }
      };

      const sortedData = [...this.filterData(data)].sort(sorter);

      return sortedData.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
});
