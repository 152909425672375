import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import LoginForm from '@/pages/LoginForm.vue';
import PasswordRecovery from '@/pages/PasswordRecovery.vue';
import CompanySelector from '@/pages/CompanySelector.vue';
import PasswordReset from '@/pages/PasswordReset.vue';
import PasswordRecoveryRedirect from '@/pages/PasswordRecoveryRedirect.vue';
import PasswordResetConfirmation from '@/pages/PasswordResetConfirmation.vue';
import InvitationForm from '@/pages/InvitationForm.vue';
import RegisterForm from '@/pages/RegisterForm.vue';
import VerifyEmail from '@/pages/VerifyEmail.vue';
import DemoRequest from '@/pages/DemoRequest.vue';
import DemoFinish from '@/pages/DemoFinish.vue';
import LeadForm from '@/pages/LeadForm.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { path: '/login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/password-recovery',
    name: 'Password recovery',
    component: PasswordRecovery,
  },
  {
    path: '/password-recovery-redirect',
    name: 'Password recovery redirect',
    component: PasswordRecoveryRedirect,
  },
  {
    path: '/password-reset/:token',
    name: 'Password reset',
    component: PasswordReset,
  },
  {
    path: '/password-reset-confirmation',
    name: 'Password reset confirmation',
    component: PasswordResetConfirmation,
  },
  {
    path: '/company-selector',
    name: 'Company Selector',
    component: CompanySelector,
  },
  {
    path: '/invitation/:token',
    name: 'Invitation',
    component: InvitationForm,
  },
  {
    path: '/verify-email/:token',
    name: 'Verification',
    component: VerifyEmail,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterForm,
  },
  {
    path: '/demo',
    name: 'Vraag demo aan',
    component: DemoRequest,
  },
  {
    path: '/lead',
    name: 'Lead formulier',
    component: LeadForm,
  },
  {
    path: '/demo-finish',
    name: 'Demo aangevraagd',
    component: DemoFinish,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
