<script setup lang="ts"></script>

<template>
  <form>
    <h2>Uitnodiging verstuurd</h2>
    <p class="tagline">
      Uw demo omgeving is klaar voor gebruik! <br />Check uw e-mail voor een
      uitnodiging naar de demo.
    </p>
    <router-link to="/login">Ga naar de inlogpagina</router-link>
  </form>
</template>

<style scoped lang="scss"></style>
