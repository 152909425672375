<template>
  <svg
    width="179"
    height="32"
    viewBox="0 0 179 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M93.8751 5.43032C93.8751 6.56163 93.8859 6.57241 94.7609 6.57241C95.5279 6.57241 95.4955 6.16298 95.4955 14.4916C95.4955 20.9024 95.4739 21.9583 95.3334 22.163C95.2146 22.3354 95.0093 22.4108 94.5232 22.4647L93.8643 22.5401L93.8967 23.5313L93.9291 24.5118L97.0402 24.5441L100.141 24.5657V23.4882V22.4108H99.6653C98.8119 22.4108 98.8443 22.497 98.8443 20.4822V18.6936L99.7949 18.4674C100.313 18.3381 100.767 18.2626 100.8 18.3057C100.843 18.3381 101.545 19.7603 102.355 21.4626L103.846 24.5657H105.931H108.027V23.499V22.4431L107.27 22.3892C106.849 22.3569 106.482 22.3138 106.46 22.2923C106.439 22.26 105.79 21.0209 105.034 19.534L103.641 16.8189L104.418 16.0216C105.272 15.1381 105.866 14.0929 106.093 13.0909C106.212 12.563 106.255 12.4983 106.503 12.4983C106.655 12.4983 106.968 12.466 107.195 12.4337L107.594 12.3583V11.3562V10.3435H104.678H101.761V11.3562V12.3583L102.301 12.4121C102.949 12.4768 103.165 12.6384 103.165 13.0586C103.165 14.2546 101.124 16.0323 99.3628 16.3663L98.8443 16.4525V10.4404V4.41753H96.3597H93.8751V5.43032Z"
      fill="currentColor" />
    <path
      d="M4.21313 15.9138V24.2962L4.4724 24.8565C4.99093 25.9663 5.95236 26.3542 7.95085 26.268C9.30119 26.2033 9.73329 26.0633 10.295 25.4814C11.0188 24.738 11.0188 24.7057 11.0188 18.8659C11.0188 12.94 10.9972 12.7138 10.2518 11.2161C9.62527 9.95551 8.59901 8.93194 7.3351 8.30702C6.44928 7.87605 5.2718 7.55281 4.56962 7.54204H4.21313V15.9138Z"
      fill="currentColor" />
    <path
      d="M12.9634 12.3905C12.9634 16.9696 12.9742 17.2606 13.1794 17.8101C13.4927 18.6289 14.0328 19.2107 14.8754 19.6309C15.4912 19.9326 15.7073 19.9757 16.3554 19.9757C17.6841 19.9649 18.7212 19.34 19.3477 18.1872L19.715 17.5084L19.7474 12.5198L19.7799 7.54204H16.3662H12.9634V12.3905Z"
      fill="currentColor" />
    <path
      d="M26.8123 7.75754C24.6194 8.32858 22.8369 9.94474 22.0591 12.0673L21.7675 12.8754L21.735 18.575L21.7026 24.2747L21.9943 24.8565C22.5452 25.9878 23.4527 26.3542 25.4512 26.268C26.7691 26.2141 27.212 26.0633 27.7413 25.5353C28.5191 24.7703 28.4651 25.4383 28.4975 16.0215L28.5191 7.54205L28.0654 7.55282C27.8061 7.55282 27.2444 7.64979 26.8123 7.75754Z"
      fill="currentColor" />
    <path
      d="M48.0719 10.031C46.8944 10.2249 45.7061 10.9899 44.9607 12.0135L44.7339 12.3367L44.7231 11.3347V10.3435H42.4005H40.0779V11.3562C40.0779 12.4768 40.0887 12.4983 40.9205 12.4983C41.1258 12.4983 41.3527 12.5845 41.4823 12.7138C41.6875 12.9185 41.6983 13.0694 41.6983 17.4869C41.6983 22.7125 41.774 22.3354 40.6937 22.4647L40.0671 22.5401L40.0995 23.5313L40.132 24.5118L43.2971 24.5441L46.4515 24.5657V23.4882V22.4108H45.9978C45.7385 22.4108 45.4253 22.3785 45.2956 22.3461L45.0472 22.2815V18.7259C45.0472 16.5711 45.0904 14.998 45.166 14.7286C45.328 14.0714 45.7817 13.468 46.3867 13.0909C46.8188 12.8323 47.0565 12.7677 47.683 12.7354C48.7309 12.6923 49.3142 12.9724 49.6923 13.7374L49.9624 14.2761L49.9948 18.0364C50.0164 20.1158 50.0056 21.9152 49.9732 22.0445C49.8976 22.3138 49.4222 22.5185 48.8713 22.5185H48.504V23.5421V24.5657H51.5828H54.6615V23.4882V22.4108H54.0134H53.3652V18.769C53.3652 14.7286 53.4084 14.3946 54.035 13.6404C55.0828 12.3798 57.2974 12.4014 58.0104 13.6835L58.2804 14.1684L58.3128 17.9825C58.3344 20.0835 58.3236 21.9152 58.2912 22.0552C58.2264 22.303 57.6971 22.5185 57.1245 22.5185H56.8221V23.5421V24.5657H60.0088H63.1956V23.4882V22.4216L62.4718 22.3892L61.7373 22.3569L61.6725 17.8855C61.6076 13.1556 61.586 12.9293 61.0567 11.895C59.998 9.83705 56.552 9.30911 54.2942 10.8606C54.0566 11.0222 53.6569 11.4317 53.4084 11.7764L52.9439 12.4014L52.717 11.9596C52.1985 10.9468 51.1399 10.2357 49.8003 10.031C49.0009 9.9017 48.8929 9.9017 48.0719 10.031Z"
      fill="currentColor" />
    <path
      d="M71.2111 10.031C70.1741 10.1495 68.7913 10.6344 68.0244 11.1408C66.7496 11.9812 65.745 13.4896 65.2805 15.2458C64.9996 16.3232 64.9996 19.7818 65.2805 20.7946C65.7558 22.4754 66.6092 23.6175 67.8407 24.2424C70.0769 25.3737 73.0476 24.9643 74.3979 23.3374L74.722 22.9387L74.7976 23.4451C74.83 23.7253 74.8624 24.0916 74.8624 24.2532V24.5657H76.969H79.0755V23.5421V22.5185H78.5893C78.2113 22.5185 78.0276 22.4539 77.8332 22.2492L77.5631 21.9906V16.4418V10.9037L77.0554 10.7313C76.1047 10.4189 74.7112 10.1387 73.5769 10.0202C72.3454 9.90169 72.3346 9.90169 71.2111 10.031ZM73.9766 12.7138L74.3223 12.8431V16.4094C74.3223 18.6936 74.2791 20.1374 74.2035 20.4175C73.8578 21.635 72.8315 22.3461 71.4056 22.3569C70.1525 22.3569 69.4179 21.8721 68.8778 20.6869C68.1756 19.1461 68.2836 15.9138 69.0722 14.3408C69.4719 13.5542 69.8392 13.1879 70.5738 12.8216C71.2327 12.4876 71.2651 12.4768 72.4318 12.5307C73.0908 12.563 73.7822 12.6384 73.9766 12.7138Z"
      fill="currentColor" />
    <path
      d="M88.7115 10.031C87.534 10.2357 86.2809 11.0977 85.7732 12.0566L85.5679 12.4445L85.5571 11.3886V10.3435H83.1805H80.804V11.3562C80.804 12.4768 80.8148 12.4983 81.6466 12.4983C81.841 12.4983 82.1003 12.5522 82.2191 12.6061C82.4136 12.7138 82.4244 12.8862 82.4244 17.4546C82.4244 22.8202 82.5216 22.3246 81.4845 22.4647L80.912 22.5401V23.5529V24.5657H84.4769H88.0417V23.4882V22.4108H87.318C86.9075 22.4108 86.3997 22.3785 86.1729 22.3354L85.7624 22.2707L85.8056 18.5428C85.8488 14.3192 85.8596 14.2438 86.6374 13.4573C87.1991 12.897 87.8689 12.66 88.6575 12.7461C89.3597 12.8216 89.4461 12.9832 89.4461 14.2438V15.2997H89.9646C90.2455 15.2997 90.872 15.2674 91.369 15.2243L92.2548 15.1596V12.8216V10.4727L91.9631 10.365C91.0881 10.0202 89.6189 9.86938 88.7115 10.031Z"
      fill="currentColor" />
    <path
      d="M114.336 10.0309C112.207 10.3002 110.641 11.313 110.068 12.7999C109.701 13.7373 109.712 15.2457 110.101 16.0646C110.673 17.3036 111.678 17.9285 114.163 18.5858C115.718 18.9952 116.064 19.1353 116.42 19.5231C117.177 20.3204 116.961 21.6565 115.999 22.1952C115.437 22.5076 114.044 22.6154 113.147 22.4215C112.315 22.2383 112.132 21.9582 112.132 20.9346V20.1373L110.976 20.2127C110.328 20.245 109.777 20.3204 109.723 20.3528C109.68 20.3959 109.658 21.247 109.669 22.2491L109.701 24.0699L110.501 24.3178C111.656 24.6841 113.093 24.8888 114.454 24.8888C117.187 24.878 118.991 24.0268 119.834 22.3137C120.158 21.6457 120.18 21.5487 120.18 20.3635C120.18 19.2969 120.147 19.0598 119.931 18.6289C119.391 17.5191 118.354 16.8619 116.107 16.2046C113.687 15.5043 113.104 15.0625 113.104 13.942C113.104 13.1662 113.515 12.6814 114.379 12.4336C115.07 12.2289 116.453 12.272 116.961 12.5198C117.252 12.6598 117.263 12.7029 117.317 13.5541C117.349 14.039 117.393 14.4484 117.425 14.4484C117.457 14.4592 118.03 14.4268 118.7 14.3837L119.91 14.3083L119.888 12.6167L119.856 10.9359L119.424 10.7312C118.732 10.408 117.695 10.1494 116.604 10.0201C115.491 9.90159 115.297 9.90159 114.336 10.0309Z"
      fill="currentColor" />
    <path
      d="M130.064 10.031C128.919 10.2249 127.806 10.936 127.018 11.9596L126.726 12.3367L126.715 11.3347V10.3435H124.393H122.07V11.3562C122.07 12.4876 122.081 12.4983 122.945 12.4983C123.712 12.4983 123.69 12.3475 123.69 17.4869C123.69 21.3441 123.669 22.0983 123.539 22.2276C123.453 22.3138 123.107 22.4216 122.783 22.4647L122.178 22.5293V23.5529V24.5657H125.311H128.444V23.4882V22.4108H127.99C127.731 22.4108 127.417 22.3785 127.288 22.3461L127.039 22.2815V18.7044C127.039 16.6142 127.083 14.9764 127.147 14.7394C127.493 13.5327 128.562 12.7138 129.805 12.7138C130.194 12.7138 130.669 12.7785 130.863 12.8647C131.328 13.0586 131.749 13.6189 131.89 14.2222C132.063 14.9657 132.041 22.0014 131.868 22.2384C131.782 22.3569 131.522 22.4431 131.112 22.4862L130.485 22.5509L130.518 23.5313L130.55 24.5118H133.629H136.708V23.4882V22.4647L136.06 22.4108L135.411 22.3569L135.379 19.0169C135.357 17.1744 135.379 15.429 135.411 15.1273C135.509 14.3408 136.038 13.5004 136.686 13.1017C137.583 12.5522 138.965 12.5845 139.624 13.1663C140.283 13.7374 140.327 14.0929 140.327 18.3057C140.327 20.3529 140.283 22.0983 140.24 22.1738C140.132 22.3354 139.527 22.5185 139.106 22.5185H138.803L138.836 23.5098L138.868 24.5118L142.033 24.5441L145.188 24.5657V23.499V22.4216L144.464 22.3892L143.729 22.3569L143.665 17.8317C143.621 14.5347 143.567 13.1879 143.47 12.8754C143.232 12.1428 142.833 11.4424 142.411 11.033C141.266 9.88015 138.76 9.61079 137.032 10.4512C136.351 10.7744 135.455 11.5825 135.184 12.0997L135.022 12.4014L134.569 11.7226C133.845 10.6344 132.84 10.0956 131.306 9.97712C130.95 9.9448 130.388 9.97712 130.064 10.031Z"
      fill="currentColor" />
    <path
      d="M152.966 10.0309C150.244 10.3757 148.126 12.3366 147.327 15.2565C147.014 16.4094 146.981 19.1892 147.273 20.2558C147.889 22.5185 149.055 23.8222 151.097 24.5333C151.875 24.8026 152.069 24.8242 153.614 24.835C155.04 24.835 155.418 24.7919 156.153 24.5871C157.125 24.307 158.583 23.639 158.583 23.4774C158.583 23.3912 157.741 21.3333 157.676 21.2579C157.665 21.2363 157.308 21.3979 156.898 21.6026C155.796 22.1521 154.943 22.3353 153.776 22.2814C152.955 22.2491 152.717 22.1952 152.22 21.9259C151.281 21.4087 150.622 20.4282 150.514 19.34L150.46 18.7797L152.22 18.7151C154.889 18.6181 156.358 18.1871 157.47 17.1851C158.389 16.377 158.886 14.9548 158.756 13.5757C158.659 12.4444 158.41 11.8733 157.73 11.1946C157.017 10.4834 156.325 10.1925 154.997 10.0201C154.014 9.90163 153.97 9.90163 152.966 10.0309ZM154.716 12.4983C155.85 12.8107 156.131 14.5454 155.191 15.4612C154.597 16.0646 153.776 16.2909 152.004 16.3555L150.449 16.4094L150.514 15.8383C150.546 15.5366 150.74 14.9441 150.924 14.5238C151.669 12.9185 153.214 12.0888 154.716 12.4983Z"
      fill="currentColor" />
    <path
      d="M168.932 10.0202C167.582 10.2573 166.393 10.9468 165.767 11.8519L165.443 12.3152L165.41 11.3347L165.378 10.3435H163.012H160.635V11.3562C160.635 12.4876 160.646 12.4983 161.511 12.4983C162.278 12.4983 162.256 12.3367 162.256 17.53C162.256 21.1071 162.223 22.1953 162.126 22.2815C162.051 22.3354 161.705 22.4216 161.37 22.4539L160.744 22.5293V23.5529V24.5657H163.876H167.009V23.4882V22.4216L166.339 22.3892L165.659 22.3569V18.7475C165.659 15.4613 165.68 15.095 165.864 14.6101C166.318 13.4249 167.366 12.7138 168.662 12.7138C169.526 12.7138 169.991 12.8862 170.39 13.3495C170.909 13.9637 170.941 14.3084 170.952 18.3381C170.952 22.6155 171.006 22.3785 169.991 22.4862L169.375 22.5509L169.407 23.5313L169.44 24.5118L172.605 24.5441L175.759 24.5657V23.499V22.4216L175.035 22.3892L174.301 22.3569L174.247 17.7239C174.193 13.5758 174.171 13.0371 173.988 12.5522C173.383 10.9037 172.259 10.128 170.25 9.9879C169.807 9.95557 169.213 9.96635 168.932 10.0202Z"
      fill="currentColor" />
  </svg>
</template>
