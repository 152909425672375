import App from './App.vue';
import router from './router';

import { createApp, provide, h } from 'vue';
import { apolloClient } from '@/api/Api';
import { createApolloProvider } from '@vue/apollo-option';
import { DefaultApolloClient } from '@vue/apollo-composable';

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

app.use(router);
app.use(apolloProvider);

app.mount('#app');
