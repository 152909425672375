
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'checkbox-field',
  props: {
    id: String,
    title: String,
    color: {
      type: String,
      default: '#3F91F7',
    },
  },
});
