<script setup lang="ts"></script>

<template>
  <div class="loading-box">
    <video
      preload="none"
      src="https://storage.googleapis.com/marksmen-experiments/demo-loading-final.mov"
      autoplay
      muted
      loop
      height="128"
      width="128" />
    <div class="text-container">
      <p class="loading-text one">Database structuur opzetten...</p>
      <p class="loading-text two">Klantenomgeving inrichten...</p>
      <p class="loading-text three">Connecties leggen...</p>
      <p class="loading-text four">Dashboard en grafieken bouwen...</p>
      <p class="loading-text five">Finaliseren...</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading-box {
  text-align: center;
  max-width: calc(100vw - 8rem);
  width: 300px;
  height: 300px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 21px;

    .loading-text {
      font-weight: $bold;
      position: absolute;
      opacity: 0;
      color: $grey;
      animation: text-anim 4s;
      //animation-iteration-count: infinite;

      &.one {
        animation-delay: 2s;
      }

      &.two {
        animation-delay: 6s;
      }

      &.three {
        animation-delay: 10s;
      }

      &.four {
        animation-delay: 14s;
      }

      &.five {
        animation: text-anim-last 4s;
        animation-delay: 18s;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes text-anim {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  10% {
    transform: translateX(0);
    opacity: 1;
  }

  90% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}

@keyframes text-anim-last {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  10% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
