import { apolloClient } from '@/api/Api';
import LOGIN_CALL from '@/api/auth/login.gql';
import { handle_login_result } from '@/util/Auth';

export const login = async (
  username: string,
  password: string,
  rememberMe?: boolean
) => {
  const result = await apolloClient.mutate({
    mutation: LOGIN_CALL,
    variables: {
      input: {
        username,
        password,
      },
    },
  });

  const data = result.data.tokenAuth;

  handle_login_result(data.token, data.refreshToken, rememberMe);
};
